import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "breaking-barriers-with-well-designed-job-shadowing"
    }}>{`Breaking Barriers with Well-Designed Job Shadowing`}</h1>
    <p>{`This project uses `}<a parentName="p" {...{
        "href": "https://www.docz.site/"
      }}>{`Docz`}</a>{` for UI components development and styleguide generation.`}</p>
    <p>{`If you never used Docz before, try `}<a parentName="p" {...{
        "href": "https://www.docz.site/docs/getting-started"
      }}>{`“Getting Started” guide`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      